import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: false,
    token: null,
  },
  mutations: {
    setLogin (state, payload) {
      state.login = payload;
    },
    setToken (state, payload) {
      state.token = payload;
    },
  },
  getters: {
    getLogin( state ) {
      return state.login;
    }
  },
  actions: {
    check_token( {commit} ) {
      let token = localStorage.getItem('token');
      if( !token ) {
        return false
      }
      commit('setToken', token)
      commit('setLogin', true)
    }
  },
  modules: {
  }
})
